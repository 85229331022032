table {
    width: 100%;
}

table tr td {
    padding: 0px 10px;
    text-align: center;
    border: 1px solid lightgray;
}

/* table tr td:hover{
    background-color: #17a2b8;
    color:white;
} */

table tr td p {
    margin-bottom: 0px !important;
}

table tr td a {
    color: black;
}

.btn_search {
    padding: 5px 40px;
}

.btn_reset {
    padding: 5px 40px;
    margin-left: 5px;
}

/* .search_section .type #white {
    background-color:#17a2b8 ;
} */

/* .search_section .type #white a {
    color:black;
} */

.td_background {
    background-color: #17a2b8;
    color: #fff;
}

.search_section input[type="checkbox"] {
    display: none;
}

label {
    font-weight: normal !important
}

.search_section table label {
    margin-bottom: 3px;
    margin-right: 5px;
}

.search_section table label img {
    padding-top: 3px;
}

.search_section .card {
    margin-top: 5px;
}

.advancedata .col-md-1 {
    padding-right: 0px;
}
.v_table th {
    background: none;
  }

  .v_table tr:nth-child(odd) {
    background: rgba(70,134,243,.1) 0 0 no-repeat padding-box;
    color: #2399d9;
  }
  .v_table tr:nth-child(even) {
    color: #2399d9;
  }
  .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
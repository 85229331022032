/* ChooseDiamondType.css */
  .diamond-container {
    margin-top: 8%;
    height: 100vh;
  }
  
  .diamond-type {
    width: 48%;
    margin-bottom: 10px;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 48%; /* Adjust the height to fit two rows */
  }
  
  .diamond-type img {
    max-width: 100%;
    height: auto;
  }
  
  .diamond-title {
    margin-top: 5px;
    font-weight: bold;
  }
  
  .selected {
    background-color: #f0f0f0;
    border-color: #000;
  }
  
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montesrrat'), url(./fonts/Montserrat/Montserrat-SemiBold.ttf) format('truetype');
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0rem;
  margin-left: -2px;
  text-align: center;
  cursor: pointer;
  cursor: hand;
  border-top: none;
  border-bottom :none;
  border-radius: 2px;
  color: rgb(23, 162, 184);
}

.page-item.active .page-link {
 
  color: white;
  background-color: rgb(43, 99, 107);
  border-color: rgb(23, 162, 184);
}



.page-link {
 
  color: rgb(23, 162, 184);
}
.checkedHighlight {
  background-color: #B0E0E6 !important;

  }
  .tableFixHead          { overflow-y: auto; height: 300px; }
.tableFixHead thead th { position: sticky; top: -1px; }

/* Just common table stuff. Really. */
 table  { width: 100%; }

th {background: white; } 

.card{
    transition: transform 0.3s ease;
    margin-top: 2%;
    border-radius: 20px;
    box-shadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
}
/* .card:hover{
    transform: scale(1.01);
    
  box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
} */

.loading{
  position: fixed;
  z-index: 999;
  height: 10em;
  width: 6em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}

@media (max-width: 768px){
  .loading{
    position: fixed;
    z-index: 999;
    height: 1em;
    width: 8em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
  }
  
}
@media (min-width : 768px) and (max-width : 1024px){
  .mobileviewspanhide{
    display: none;
  }
}

.viewinward .card-header{
  border-bottom: none;
}
.viewinward .card-body{
  padding-top: 10px;
}
.marginn { 
  padding-top: 3px;
}
.highlight-content span{
  font-weight: 700;
}
#advancesearch .col-md-1{
  padding-right: 0px;
}
.justify-content-center .card:hover {
  transform: scale(1.01);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}
.grandParentContaniner{
  display:table; height:100%; margin: 0 auto;
}

.parentContainer{
  display:table-cell; vertical-align:middle;
}
.zui-table {
  border: none;
  border-right: solid 1px #DDEFEF;

  border-spacing: 0;
  font: normal 13px Arial, sans-serif;
}
.zui-table thead th {
  background-color: #DDEFEF;
  border: none;
  color: #336B6B;
   
  text-align: left;
  text-shadow: 1px 1px 1px #fff;
  white-space: nowrap;
}
.zui-table tbody td {
  border-bottom: solid 1px #DDEFEF;
  color: #333;
  padding: 7px;
  text-shadow: 1px 1px 1px #fff;
  white-space: nowrap;
}

.zui-wrapper {
  position: relative;
}

.zui-scroller {
  margin-left: 750px;
  overflow-x: scroll;
  overflow-y: visible;
  padding-bottom: 5px;
}

.zui-table .zui-sticky-col1 {
  border-left: solid 1px #DDEFEF;
  border-right: solid 1px #DDEFEF;
  left: 0;
  
  position: absolute;
  top: auto;
  width: 34px;
  text-align: center;
  /* height: 37px; */
}

.zui-table .zui-sticky-col {
  border-left: solid 1px #DDEFEF;
  border-right: solid 1px #DDEFEF;
  left: 33px;
   
  position: absolute;
  top: auto;
  width: 120px;
  text-align: center;
  /* height: 35px; */
}

.zui-table .zui-sticky-col2{
  border-left: solid 1px #DDEFEF;
  border-right: solid 1px #DDEFEF;
  left: 372px;
   
  position: absolute;
  top: auto;
  width: 80px;
  text-align: center;
  /* height: 37px; */
  
}

.zui-table .zui-sticky-col3{
  border-left: solid 1px #DDEFEF;
    border-right: solid 1px #DDEFEF;
    left: 451px;
     
    position: absolute;
    top: auto;
    width: 60px;
    text-align: center;
    /* height: 37px; */
}

.zui-table .zui-sticky-col5{
  border-left: solid 1px #DDEFEF;
  border-right: solid 1px #DDEFEF;
  left: 690px;
   
  position: absolute;
  top: auto;
  width: 60px;
  text-align: center;
  overflow: hidden;
  /* height: 37px; */
}

.zui-table .zui-sticky-col4{
  border-left: solid 1px #DDEFEF;
  border-right: solid 1px #DDEFEF;
  left: 510px;
   
  position: absolute;
  top: auto;
  width: 180px;
  text-align: center;
  /* height: 37px; */
}
.dynamictextbox{
  width: 74px;
  height: 15px;
  font-size: 15px;
}
.top { 
  margin-top: 70px;
}
.iframe{
border: none;

}
.iframe canvas {
  width:1000px !important;
}
.texthidden{
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.zui-table .zui-sticky-col6{
  border-left: solid 1px #DDEFEF;
  border-right: solid 1px #DDEFEF;
  left: 273px;
   
  position: absolute;
  top: auto;
  width: 100px;
  text-align: center;
  /* height: 37px; */
}
.content-wrapper{
  background-color: #fff;
}
.zui-table .zui-sticky-col7 {
  border-left: solid 1px #DDEFEF;
  border-right: solid 1px #DDEFEF;
  left: 219px;
   
  position: absolute;
  top: auto;
  width: 55px;
  text-align: center;
  /* height: 35px; */
}
.zui-table .zui-sticky-col8 {
  border-left: solid 1px #DDEFEF;
  border-right: solid 1px #DDEFEF;
  left: 152px;
   
  position: absolute;
  top: auto;
  width: 68px;
  text-align: center;
  /* height: 35px; */
}

@media (min-width : 1024px){
  #pushbutton{
    display: none;
  }
}
.stone_info{
  justify-content: space-between;
  flex-wrap: wrap;
}

/* .v_table tbody td{
  color:unset;
} */


@media (max-width: 440px) {
  .main-card {
    min-width: 100%;
    /* border: 1px solid red; */
  }
}

@media (max-width:767px){
	.zui-scroller {
		display:inline-flex;
	}
  .pagination{
    flex-wrap: wrap;
  }
 
}
@media (min-width:767px) and (max-width:1024px){
	.zui-scroller {
		display:inline-flex;
	}
}
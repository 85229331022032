/**
@media screen and (max-device-width: 480px) 
    and (orientation: portrait) {
    .main {
        margin-top: 60px;
    }

    .container {
        background-color: rgb(250, 250, 250);
        padding: 0;
    }

    .header {
        background-color: rgb(250, 250, 250);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid gray;
    }

    .subhead {
        padding: 12px;
        font-size: small;
        border-right: 1px solid gray;
        height: 100%;
    }

    .s1 {
        width: 15%;
    }
    .s2 {
        width: 55%;
    }
    .s3 {
        width: 15%;
    }
    .s4 {
        width: 15%;
    }

    .listelement {
        background-color: rgb(250, 250, 250);
        margin: 12px 4px;
        height: 70px;
        border: 1px solid black;
        border-radius: 3px;
        display: flex;
    }

    .listelement > div:first-child {
        width: 15%;
      }
      
      .container-left {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .listelement > div:last-child {
        width: 85%;
      }

      .container-right > div:first-child {
        border-bottom: 1px solid black;
        width: 90%;
      }
      .container-right > div:nth-child(2) {
        width: 90%;
      }
      .container-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
      }

      .container-r-up > span {
        font-size:14px;
        margin-right: 2px;
      }
      .discount {
        margin-left: 10px;
        color: blue;
      }

      .detail {
        width: 60%;
      }


      .sort-icon {
        margin-left: 4px;
        fill: currentColor;
      }

      .filters {
        width: 100%;
        overflow: hidden;
      }
      .up-filter {
        display: flex;
        margin-top: 25px;
      }
      .down-filter {
        display: flex;
      }

      .down-filter > button {
        margin: 2px;
      }

      .diamond-desc {
        border: 1px solid #ccc;
        background-color: white;
      }
      
      .modal-options {
        display: flex;
      }
      
      .option {
        cursor: pointer;
        padding: 10px;
        border: 1px solid #ccc;
        flex: 1;
        text-align: center;
      }
      
      .option.active {
        background-color: #f0f0f0;
        font-weight: bold;
      }
      
      .modal-content {
        margin-top: 10px;
      }

      .pagination {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 16px; 
      }
    
      .mobile-pagination {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: "3px solid red";
      }
    
      .mobile-pagination button {
        padding: 4px 8px;
        border: 1px solid #ccc;
        border-radius: 4px; 
      }

}

*/

.header {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px 10px;
  border-bottom: 2px solid #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  font-weight: 600;
  color: #333;
}

.subhead {
  padding: 12px;
  font-size: 14px;
  height: 100%;
  color: rgb(23, 162, 184);
  text-align: center;
}

.subhead:hover {
  background-color: #f0f8ff;
  cursor: pointer;
  color: rgb(23, 162, 184); 
}

.s1 {
  width: 10%;
}

.s2 {
  width: 50%;
}

.s3, .s4 {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sort-icon {
  margin-left: 8px;
  fill: rgb(23, 162, 184);
  transition: fill 0.3s ease;
}

.sort-icon:hover {
  fill: rgb(23, 162, 184);
}

.listelement {
  background-color: white;
  margin: 12px 4px;
  height: auto;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  display: flex;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.listelement:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.container-left {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.container-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 85%;
  padding: 10px;
  cursor: pointer;
}

.container-r-up, .container-r-down {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.detail {
  font-weight: 600;
  font-size: 16px;
  color: #333;
}

.font-weight-bold {
  font-weight: 700;
  color: rgb(23, 162, 184);
}

.discount {
  margin-left: 10px;
  color: rgb(23, 162, 184);
  font-weight: 600;
  background-color: #f0f8ff;
  padding: 3px 8px;
  border-radius: 4px;
}

.listelement .fa-video {
  color: rgb(23, 162, 184);
  font-size: 18px;
}

.container-r-down .font-weight-bold {
  margin-left: 10px;
  color: rgb(23, 162, 184);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.pagination button {
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: white;
  color: rgb(23, 162, 184);
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: rgb(23, 162, 184);
  color: white;
}

/* Mobile styles for smaller devices */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .listelement {
      margin: 8px 2px;
      height: auto;
      border-radius: 5px;
  }

  .container-left, .container-right {
      padding: 8px;
  }

  .detail {
      font-size: 14px;
  }

  .discount {
      font-size: 14px;
  }
  
  .pagination button {
      padding: 6px 10px;
  }
}

/* Modal options styling */
.modal-options {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.option {
  cursor: pointer;
  padding: 12px;
  border: 1px solid rgb(23, 162, 184);
  border-radius: 6px;
  flex: 1;
  text-align: center;
  color: rgb(23, 162, 184);
  transition: background-color 0.3s ease;
}

.option.active {
  background-color: #f0f8ff;
  font-weight: bold;
}

.option:hover {
  background-color: rgb(23, 162, 184);
  color: white;
}


.parameter-table table,
.cost-table table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.parameter-table th,
.cost-table th {
    background-color: rgb(23, 162, 184);
    color: white;
    padding: 12px;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    text-align: left;
}

.parameter-table td,
.cost-table td {
    padding: 12px;
    font-size: 14px;
    border: 1px solid #e0e0e0;
    text-align: left;
    color: #333;
}

.parameter-table tr:nth-child(even),
.cost-table tr:nth-child(even) {
    background-color: #f8f9fa;
}

.parameter-table tr:hover,
.cost-table tr:hover {
    background-color: #e0f3ff;
}

.parameter-table th,
.cost-table th {
    background-color: rgb(23, 162, 184); 
    color: white;
}

.parameter-table td:hover,
.cost-table td:hover {
    background-color: #f0f8ff;
    cursor: pointer;
}

.parameter-table td:first-child,
.cost-table td:first-child {
    color: rgb(23, 162, 184);
    font-weight: 600;
}

.parameter-table th,
.cost-table th {
    text-align: left;
    font-weight: bold;
}

.parameter-table td,
.cost-table td {
    text-align: left;
    padding: 12px;
}
